import Sizes from "./Utils/Sizes.js";
import Time from "./Utils/Time.js";
import * as THREE from 'three'
import Camera from "./Camera.js";
import Renderer from "./Renderer.js";
import Scene from "./Scene.js";
import Resources from "./Utils/Resources.js";
import Materials from './WorkdenRoom/Materials.js'
import sources from "./sources.js"
import Debug from "./Utils/Debug.js";
import PostProcessing from './PostProcessing.js'
import RayCaster from './Raycaster.js'
import Controller from "./Controller.js";

let instance = null;

export default class Experience
{
    constructor(pCanvas, pIsSceneRenderingEnabled)
    {
        // Singleton instance
        if (instance)
        {
            //console.log(" Returning the Singleton instance");
            return instance;
        }
        instance = this;

        console.log('Here starts a great experience!');

        // NOTE : Global Access for console access for debugging use
        window.experience = this;

        // Options
        this.canvas = pCanvas;

        // Setup        
        this.isCameraSplitScreenDebugModeEnabled = false;
        this.debug = new Debug();
        this.sizes = new Sizes();
        this.time = new Time();
        this.threeJSscene = new THREE.Scene();
        this.cssScene = new THREE.Scene();
        this.resources = new Resources(sources);
        this.sceneWorld = new Scene();
        this.camera = new Camera(this);
        this.renderer = new Renderer();
        this.materials = new Materials();
        this.postProcessing = new PostProcessing();
        this.controller = new Controller();        
        this.rayCaster = new RayCaster();
        this.isSceneRenderingEnabled = pIsSceneRenderingEnabled;
              

        // Listen to Resize event
        this.sizes.on('resize', () =>
        { 
            this.resize();            
        });

        // Listen to the tick event
        this.time.on('tick', () =>
        {
            this.update();
        });
    }
    
    

    resize()
    {
        //console.log(this);
        //console.log('A resize occurred');
        // Update camera on window resize
        this.camera.onWindowResize();
        this.renderer.onWindowResize();
    }

    update()
    {
        //console.log('Update the experience every frame');
        
        if (this.isSceneRenderingEnabled)
        {
            this.camera.updateControls(this.time.deltaTime);
            this.sceneWorld.update();
            
            if(this.isCameraSplitScreenDebugModeEnabled)
            {
                this.renderer.updateRenderers(true, true);
            }
            
            else if (!this.isCameraSplitScreenDebugModeEnabled)
            {
                // Use Post processing update to render instead of rendering inside canvas as Effect Composer is taking care of the first pass        
                if(this.renderer.webGLrendererInstance != null && this.postProcessing.effectComposer != null)
                {
                    //this.postProcessing.effectComposer.render();
                    
                    this.postProcessing.updatePP();
                    this.renderer.updateRenderers(false, true);
                }
            }   
        }
    }    

    destroy()
    {
        this.sizes.off('resize');
        this.time.off('tick');

        // Traverse the whole scene
        this.threeJSscene.traverse((child) => 
        {
            if (child instanceof THREE.Mesh)
            {
                //console.log(child)
                child.geometry.dispose();

                for(const key in child.material)
                {
                    const value = child.material[key];
                    if (value && typeof value.dispose ==='functions')
                    {
                        value.dispose();
                    }
                }
            }
        });

        this.camera.orbitControls.dispose();
        this.renderer.webGLrendererInstance.dispose();

        // If you have Post Processing then dispose is needed for 
        // EffectComposer and WebGLRenderTarget

        // Destroy Debug UI if active
        if(this.debug.active)
            this.debug.ui.destroy();
    }
}