import * as THREE from 'three'
import Experience from './Experience.js'

export default class Raycaster
{
    constructor()
    {
        this.experienceRef = new Experience()
        this.debugRef = this.experienceRef.debug
        this.sceneRef = this.experienceRef.threeJSscene
        this.resourcesRef = this.experienceRef.resources
        this.cameraRef = this.experienceRef.camera
        this.sizesRef = this.experienceRef.sizes
        this.performanceRef = this.experienceRef.performance
        this.postProcessingRef = this.experienceRef.postProcessing;
        this.cameraRef = this.experienceRef.camera;

        // Wait for textures and models to be loaded
        this.resourcesRef.on('ready', () =>
        {
            // Setup            
            this.workDenScene = this.experienceRef.sceneWorld.workDenScene;
            this.raycaster = new THREE.Raycaster();
            this.cursorDown = new THREE.Vector2();
            this.cursor = new THREE.Vector2();            
            this.controllerRef = this.experienceRef.controller;
            
            // Objects to test
            this.objectsToTest = [
                // Interactables
                this.workDenScene.behanceLogo,
                this.workDenScene.linkedInLogo,
                this.workDenScene.githubLogo,
                this.workDenScene.resume,
                this.workDenScene.resumeAbridged,
                this.workDenScene.monitorScreen,
                this.workDenScene.threeJsLogo,
                this.workDenScene.draftingTableSketch,
                this.workDenScene.documentFolder,
                this.workDenScene.backNote1,
                this.workDenScene.backNote2,
                this.workDenScene.backNote3,
                this.workDenScene.backNote4,
                this.workDenScene.monitorONOFFbutton,
                this.workDenScene.backNote5,
                this.workDenScene.backNote6,
                this.workDenScene.backNote7,
                this.workDenScene.desktopPcCabinet.children[1],
                this.workDenScene.macbookScreen
            ]

            // Touch listener
            this.touchedPoints = [];
            window.addEventListener('mousemove', (event) => 
            {                
                // Convert values to -1 to +1 range
                this.cursor.x = (event.clientX / this.sizesRef.width) * 2 - 1;
                this.cursor.y = -((event.clientY / this.sizesRef.height) * 2 - 1);

                // this.absX = Math.abs(this.cursor.x)
                // this.absY = Math.abs(this.cursor.y)

                this.onObjectHover(this.cursor);
            });
            
            // Click listener - Part A
            window.addEventListener('pointerdown', (event) =>
            {
                this.touchedPoints.push(event.pointerId)

                this.cursorXMin = Math.abs((event.clientX / this.sizesRef.width * 2 - 1)*0.9)
                this.cursorXMax = Math.abs((event.clientX / this.sizesRef.width * 2 - 1)*1.1)

                this.cursorYMin = Math.abs((event.clientY / this.sizesRef.height * 2 - 1)*0.9)
                this.cursorYMax = Math.abs((event.clientY / this.sizesRef.height * 2 - 1)*1.1)

            });

            // Click listener - Part B
            window.addEventListener('pointerup', (event) =>
            {
                // Convert values to -1 to +1 range
                this.cursor.x = (event.clientX / this.sizesRef.width) * 2 - 1;
                this.cursor.y = -((event.clientY / this.sizesRef.height) * 2 - 1);

                this.absX = Math.abs(this.cursor.x)
                this.absY = Math.abs(this.cursor.y)

                if(this.touchedPoints.length === 1 && 
                this.absX > this.cursorXMin && this.absX < this.cursorXMax &&
                this.absY > this.cursorYMin && this.absY < this.cursorYMax)
                {
                    this.click(this.cursor)
                    this.touchedPoints = []
                }
                else
                {   
                    this.touchedPoints = []
                }
            });
        })        
    }   

    onObjectHover(cursor)
    {       
        // Mouse events interacted objects container for Outline Pass
        this.selectedObjects = []; 
        this.raycaster.setFromCamera(cursor, this.cameraRef.cameraInstance);       

        //Object click listener
        this.intersectsObjects = this.raycaster.intersectObjects(this.objectsToTest);

        if(this.intersectsObjects.length)
        {
            //console.log(this.intersectsObjects)
            this.selectedModel = this.intersectsObjects[0].object;            

            switch(this.selectedModel)
            {
                case this.workDenScene.behanceLogo.children[0]:
                    //console.log("Behance Logo");
                    this.selectedObjects.push(this.workDenScene.behanceLogo);
                    this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                    this.postProcessingRef.outlinePass.enabled = true;
                    this.postProcessingRef.outlinePass.visibleEdgeColor.set('#FF0000');
                    this.postProcessingRef.outlinePass.hiddenEdgeColor.set('#FF0000');
                    break;
                case this.workDenScene.behanceLogo.children[1]:
                    //console.log("Behance Logo");
                    this.selectedObjects.push(this.workDenScene.behanceLogo);
                    this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                    this.postProcessingRef.outlinePass.enabled = true;
                    this.postProcessingRef.outlinePass.visibleEdgeColor.set('#FF0000');
                    this.postProcessingRef.outlinePass.hiddenEdgeColor.set('#FF0000');
                    break;    
                case this.workDenScene.linkedInLogo.children[0]:
                    //console.log("LinkedIn Logo");
                    this.selectedObjects.push(this.workDenScene.linkedInLogo);
                    this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                    this.postProcessingRef.outlinePass.enabled = true;
                    this.postProcessingRef.outlinePass.visibleEdgeColor.set('#FF0000');
                    this.postProcessingRef.outlinePass.hiddenEdgeColor.set('#FF0000');
                    break;
                case this.workDenScene.linkedInLogo.children[1]:
                    //console.log("LinkedIn Logo");
                    this.selectedObjects.push(this.workDenScene.linkedInLogo);
                    this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                    this.postProcessingRef.outlinePass.enabled = true;
                    this.postProcessingRef.outlinePass.visibleEdgeColor.set('#FF0000');
                    this.postProcessingRef.outlinePass.hiddenEdgeColor.set('#FF0000');
                    break;               
                case this.workDenScene.githubLogo:
                    //console.log("Github Logo");
                    this.selectedObjects.push(this.workDenScene.githubLogo);
                    this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                    this.postProcessingRef.outlinePass.enabled = true;
                    this.postProcessingRef.outlinePass.visibleEdgeColor.set('#FF0000');
                    this.postProcessingRef.outlinePass.hiddenEdgeColor.set('#FF0000');
                    break;
                case this.workDenScene.threeJsLogo:
                    //console.log("ThreeJS Logo");
                    this.selectedObjects.push(this.workDenScene.threeJsLogo);
                    this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                    this.postProcessingRef.outlinePass.enabled = true;
                    this.postProcessingRef.outlinePass.visibleEdgeColor.set('#FF0000');
                    this.postProcessingRef.outlinePass.hiddenEdgeColor.set('#FF0000');
                    break;
                case this.workDenScene.resume:
                    //console.log("Resume");
                    this.selectedObjects.push(this.workDenScene.resume);
                    this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                    this.postProcessingRef.outlinePass.enabled = true;
                    break;
                case this.workDenScene.resumeAbridged:
                    //console.log("Abridged Resume");
                    this.selectedObjects.push(this.workDenScene.resumeAbridged);
                    this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                    this.postProcessingRef.outlinePass.enabled = true;
                    break;
                case this.workDenScene.monitorScreen:
                    //console.log("Desktop PC Monitor");
                    if (this.cameraRef.cameraState != 'monitorFocussed')
                    {
                        this.selectedObjects.push(this.workDenScene.monitorScreen);
                        this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                        this.postProcessingRef.outlinePass.enabled = true;
                    }                    
                    break;
                case this.workDenScene.draftingTableSketch:
                    //console.log("Drafting Table Sketch");
                    this.selectedObjects.push(this.workDenScene.draftingTableSketch);
                    this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                    this.postProcessingRef.outlinePass.enabled = true;           
                    break;
                case this.workDenScene.documentFolder.children[0]:
                    //console.log("Document Folder");
                    this.selectedObjects.push(this.workDenScene.documentFolder);
                    this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                    this.postProcessingRef.outlinePass.enabled = true;           
                    break;
                case this.workDenScene.documentFolder.children[1]:
                    //console.log("Document Folder");
                    this.selectedObjects.push(this.workDenScene.documentFolder);
                    this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                    this.postProcessingRef.outlinePass.enabled = true;           
                    break;
                case this.workDenScene.backNote1:
                    //console.log("Back note1");
                    if (this.cameraRef.cameraState != 'default')
                    {
                        this.selectedObjects.push(this.workDenScene.backNote1);
                        this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                        this.postProcessingRef.outlinePass.enabled = true;
                    }
                    break;
                case this.workDenScene.backNote2:
                    //console.log("Back note2");
                    if (this.cameraRef.cameraState != 'default')
                    {
                        this.selectedObjects.push(this.workDenScene.backNote2);
                        this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                        this.postProcessingRef.outlinePass.enabled = true;
                    }
                    break;
                case this.workDenScene.backNote3:
                    //console.log("Back note3");
                    if (this.cameraRef.cameraState != 'default')
                    {
                        this.selectedObjects.push(this.workDenScene.backNote3);
                        this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                        this.postProcessingRef.outlinePass.enabled = true;
                    }
                    break;
                case this.workDenScene.monitorONOFFbutton:
                    //console.log("Back note4");
                    if (this.cameraRef.cameraState != 'default')
                    {
                        this.selectedObjects.push(this.workDenScene.monitorONOFFbutton);
                        this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                        this.postProcessingRef.outlinePass.enabled = true;
                    }
                    break;
                case this.workDenScene.backNote4:
                    //console.log("Back note4");
                    if (this.cameraRef.cameraState != 'default')
                    {
                        this.selectedObjects.push(this.workDenScene.backNote4);
                        this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                        this.postProcessingRef.outlinePass.enabled = true;
                    }
                    break;
                case this.workDenScene.backNote5:
                    //console.log("Back note5");
                    if (this.cameraRef.cameraState != 'default')
                    {
                        this.selectedObjects.push(this.workDenScene.backNote5);
                        this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                        this.postProcessingRef.outlinePass.enabled = true;
                    }
                    break;
                case this.workDenScene.backNote6:
                    //console.log("Back note6");
                    if (this.cameraRef.cameraState != 'default')
                    {
                        this.selectedObjects.push(this.workDenScene.backNote6);
                        this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                        this.postProcessingRef.outlinePass.enabled = true;
                    }
                    break;   
                case this.workDenScene.backNote7:
                    //console.log("Back note7");
                    if (this.cameraRef.cameraState != 'default')
                    {
                        this.selectedObjects.push(this.workDenScene.backNote7);
                        this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                        this.postProcessingRef.outlinePass.enabled = true;
                    }
                    break;                
                case this.workDenScene.desktopPcCabinet.children[1]:
                    //console.log("Desktop PC Cabinet");
                    this.selectedObjects.push(this.workDenScene.desktopPcCabinet);
                    this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                    this.postProcessingRef.outlinePass.enabled = true;
                    break;
                case this.workDenScene.macbookScreen:
                    //console.log("Macbook Screen");
                    this.selectedObjects.push(this.workDenScene.macbookScreen);
                    this.postProcessingRef.outlinePass.selectedObjects = this.selectedObjects;
                    //this.postProcessingRef.outlinePass.edgeThickness = 10;                    
                    this.postProcessingRef.outlinePass.enabled = true;
                    break;                    
            }
        }
        else
        {
            this.postProcessingRef.outlinePass.enabled = false;
            this.postProcessingRef.outlinePass.edgeThickness = 4;
            this.postProcessingRef.outlinePass.edgeStrength = 10;
            this.postProcessingRef.outlinePass.visibleEdgeColor.set('#FFFFFF');
            this.postProcessingRef.outlinePass.hiddenEdgeColor.set('#FFFFFF');
            // Clear the array if no objects to highlight
            this.selectedObjects.length = 0;
        }
            
    }

    click(cursor)
    {        
        this.raycaster.setFromCamera(cursor, this.cameraRef.cameraInstance)        

        //Object click listener
        this.intersectsObjects = this.raycaster.intersectObjects(this.objectsToTest)
        if(this.intersectsObjects.length)
        {
            //console.log(this.intersectsObjects)
            this.selectedModel = this.intersectsObjects[0].object            

            switch(this.selectedModel)
            {
                case this.workDenScene.behanceLogo.children[0]:
                    //console.log("Behance Logo clicked");
                    this.controllerRef.interactiveControls.logoClusterClicked('behance');
                    break;
                case this.workDenScene.behanceLogo.children[1]:
                    //console.log("Behance Logo clicked");
                    this.controllerRef.interactiveControls.logoClusterClicked('behance');
                    break;    
                case this.workDenScene.linkedInLogo.children[0]:
                    //console.log("LinkedIn Logo clicked");
                    this.controllerRef.interactiveControls.logoClusterClicked('linkedIn');
                    break;
                case this.workDenScene.linkedInLogo.children[1]:
                    //console.log("LinkedIn Logo clicked");
                    this.controllerRef.interactiveControls.logoClusterClicked('linkedIn');
                    break;               
                case this.workDenScene.githubLogo:
                    //console.log("Github Logo clicked");
                    this.controllerRef.interactiveControls.logoClusterClicked('github');
                    break;
                case this.workDenScene.resume:
                    //console.log("Resume clicked");
                    this.controllerRef.interactiveControls.resumeGroupClicked('resume');
                    break;
                case this.workDenScene.resumeAbridged:
                    //console.log("Abridged Resume clicked");                    
                    this.controllerRef.interactiveControls.resumeGroupClicked('resumeAbridged');
                    break;
                case this.workDenScene.threeJsLogo:
                    //console.log("Three JS logo clicked");                    
                    this.controllerRef.interactiveControls.threeJSlogoClicked();
                    break;
                case this.workDenScene.monitorScreen:
                    //console.log("Desktop Monitor clicked");
                    this.controllerRef.interactiveControls.desktopMonitorClicked();
                    break;
                case this.workDenScene.draftingTableSketch:
                    //console.log("Drafting Table Sketch clicked");
                    this.controllerRef.interactiveControls.draftingTableSketchClicked();
                    break;
                case this.workDenScene.documentFolder.children[0]:
                    //console.log("Document Folder clicked");
                    this.controllerRef.interactiveControls.documentFolderClicked();
                    break;
                case this.workDenScene.documentFolder.children[1]:
                    //console.log("Document Folder clicked");
                    this.controllerRef.interactiveControls.documentFolderClicked();
                    break;
                case this.workDenScene.backNote1:
                    //console.log("Back Sticky Note clicked");
                    this.controllerRef.interactiveControls.backStickyNoteClicked();
                    break;
                case this.workDenScene.backNote2:
                    //console.log("Back Sticky Note clicked");
                    this.controllerRef.interactiveControls.backStickyNoteClicked();
                    break;
                case this.workDenScene.backNote3:
                    //console.log("Back Sticky Note clicked");
                    this.controllerRef.interactiveControls.backStickyNoteClicked();
                    break;
                case this.workDenScene.monitorONOFFbutton:
                    //console.log("Back Sticky Note clicked");
                    this.controllerRef.interactiveControls.backStickyNoteClicked();
                    break;
                case this.workDenScene.backNote4:
                    //console.log("Back Sticky Note clicked");
                    this.controllerRef.interactiveControls.backStickyNoteClicked();
                    break;
                case this.workDenScene.backNote5:
                    //console.log("Back Sticky Note clicked");
                    this.controllerRef.interactiveControls.backStickyNoteClicked();
                    break;
                case this.workDenScene.backNote6:
                    //console.log("Back Sticky Note clicked");
                    this.controllerRef.interactiveControls.backStickyNoteClicked();
                    break;
                case this.workDenScene.backNote7:
                    //console.log("Back Sticky Note clicked");
                    this.controllerRef.interactiveControls.backStickyNoteClicked();
                    break;                
                case this.workDenScene.desktopPcCabinet.children[1]:
                    //console.log("Desktop PC Cabinet clicked");
                    this.controllerRef.interactiveControls.desktopPcCabinetClicked();
                    break;
                case this.workDenScene.macbookScreen:
                    //console.log("Macbook clicked");
                    this.controllerRef.interactiveControls.macbookScreenClicked();
                    break;
            }
            
            this.togglePPoutline();
        }
    }

    togglePPoutline = async (duration) =>
    {
        this.postProcessingRef.outlinePass.enabled = false;
        await this.sleep(2000);
        this.postProcessingRef.outlinePass.enabled = true;
    }

    sleep(ms) 
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}