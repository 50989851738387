import Experience from './Experience.js';
import Workden from './WorkdenRoom/Workden.js';
import Environment from "./WorkdenRoom/Lighting.js";
import MonitorScreenIFrame from "./WorkdenRoom/Monitor.js";
import CoffeeSmoke from "./WorkdenRoom/CoffeeSteam.js";
import MacbookScreen from "./WorkdenRoom/MacbookScreen.js";

export default class Scene
{
    constructor()
    {
        // get ref to Experience singleton
        this.experienceRef = new Experience();
        this.sceneRef = this.experienceRef.threeJSscene;        
        this.resourcesRef = this.experienceRef.resources;       

        //Wait for resources 'ready' event
        this.resourcesRef.on('ready', () =>
        {
            // Setup
            this.workDenScene = new Workden();
            this.monitorScreenIFrame = new MonitorScreenIFrame();
            this.macbookScreen = new MacbookScreen();
            this.deskCoffeeCupSteam = new CoffeeSmoke(this.workDenScene.deskCoffeeCupSteamMarker);
            this.rugCoffeeCupSteam = new CoffeeSmoke(this.workDenScene.rugCoffeeCupSteamMarker);

            // Takes care of setting up environment lighting
            this.environment = new Environment();
        });

        // // Test Mesh
        // const testMesh = new THREE.Mesh(
        //     new THREE.BoxGeometry(1,1,1),
        //     new THREE.MeshStandardMaterial({color: 0xFF0000})
        // );
        // testMesh.scale.set(1000, 1000, 1000)
        // this.sceneRef.add(testMesh);
    }

    update()
    {
        if (this.workDenScene)
        {
            this.workDenScene.update();
        }       

        if (this.monitorScreenIFrame)
        {
            this.monitorScreenIFrame.update();
        }

        if (this.macbookScreen)
        {
            this.macbookScreen.update();
        }

        if (this.deskCoffeeCupSteam)
        {
            this.deskCoffeeCupSteam.update();
        }

        if (this.rugCoffeeCupSteam)
        {
            this.rugCoffeeCupSteam.update();
        }

        // if (this.environment)
        // {
        //     console.log(this.environment.sunLight.color)
        // }
    }
}