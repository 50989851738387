import Experience from '../Experience';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import * as THREE from 'three';

export default class MacbookScreen 
{
    constructor()
    {
        this.experienceRef = new Experience();
        this.webGLsceneRef = this.experienceRef.threeJSscene;
        this.cssSceneRef = this.experienceRef.cssScene;
        this.sizesRef = this.experienceRef.sizes;
        this.workDenScene = this.experienceRef.sceneWorld.workDenScene;
        this.cameraRef = this.experienceRef.camera;
        this.position = new THREE.Vector3(0, 0 , 0);
        this.rotation = new THREE.Euler(0, 0, 0);      
        this.debugRef = this.experienceRef.debug;
        this.resourcesRef = this.experienceRef.resources;

        // Create macbook Screen HTML container
        this.createHTMLelement();
    }

    createHTMLelement()
    {
        // Calculate the bounding box of the macBook Screen mesh 
        const boundingBox = new THREE.Box3().setFromObject(this.workDenScene.macbookScreen); 
        this.meshBoundingBoxSize = boundingBox.getSize(new THREE.Vector3());
        this.meshBoundingBoxSize.x += 10;
        this.meshBoundingBoxSize.y += 30;        

        // Create HTML div
        this.macbookScreenHTMLContainer = document.createElement('div');
        // Set div attributes
        // PROD
        this.macbookScreenHTMLContainer.style.position = 'absolute';
        this.macbookScreenHTMLContainer.style.width = `${this.meshBoundingBoxSize.x}px`;
        this.macbookScreenHTMLContainer.style.height = `${this.meshBoundingBoxSize.y}px`;
        this.macbookScreenHTMLContainer.style.padding = '0px';        
        this.macbookScreenHTMLContainer.style.opacity = 1;
        this.macbookScreenHTMLContainer.style.overflow = 'visible';
        this.macbookScreenHTMLContainer.style.backgroundColor = '#FFFFFF00';
        this.macbookScreenHTMLContainer.style.zIndex = 10;
        //this.macbookScreenHTMLContainer.style.backgroundImage = 'url("/textures/unbaked/FirewatchWallpaperBlurredDownscaled.jpg")';
        //this.macbookScreenHTMLContainer.style.backgroundSize = 'cover'; // Cover the entire background
        //this.macbookScreenHTMLContainer.style.backgroundPosition = 'center';
        this.macbookScreenHTMLContainer.className = 'macbookScreenCSSFrame';
        this.macbookScreenHTMLContainer.id = 'macbookScreen';

        // Create a child HTML element (UI element) 
        this.textMessageDiv = document.createElement('input');
        this.textMessageDiv.type = 'text'; 
        this.textMessageDiv.placeholder = 'Password...';
        this.textMessageDiv.style.width = '150px'; 
        this.textMessageDiv.style.height = '40px';        
        this.textMessageDiv.style.backgroundColor = 'rgba(255, 255, 255, 0.25)';
        // text color
        this.textMessageDiv.style.color = 'rgba(255, 255, 255, 0.5)';
        this.textMessageDiv.style.position = 'absolute';
        this.textMessageDiv.style.top = '35%'; 
        this.textMessageDiv.style.left = '50%'; 
        this.textMessageDiv.style.textAlign = 'center'; // Center text horizontally 
        this.textMessageDiv.style.lineHeight = this.textMessageDiv.style.height; // Center text vertically
        this.textMessageDiv.style.fontSize = '18px';
        this.textMessageDiv.style.fontStyle = 'italic';
        this.textMessageDiv.style.transform = 'translate(-50%, -50%) rotateX(180deg)';
        this.textMessageDiv.style.borderRadius = '10px'; // Add border radius for rounded corners
        this.macbookScreenHTMLContainer.appendChild(this.textMessageDiv);
        
        // Create CSS plane
        this.createCssPlane();
    }
    

    /**
     * Creates a CSS plane and GL plane to properly occlude the CSS plane
     * @param element the element to create the css plane for
     */
    createCssPlane()
    {        
        // Create CSS3D object
        this.css3Dobject = new CSS3DObject(this.macbookScreenHTMLContainer);            
        this.css3Dobject.scale.set(1,1,1);
        // Add to CSS scene
        this.cssSceneRef.add(this.css3Dobject);

        // Create GL plane
        // Create plane geometry
        const geometry = new THREE.PlaneGeometry(
            this.meshBoundingBoxSize.x,
            this.meshBoundingBoxSize.y
        );

        const material = new THREE.MeshBasicMaterial({ color: 0xFFFFFF });
        material.opacity = 0.0;
        material.side = THREE.DoubleSide;
        // NoBlending allows the GL plane to occlude the CSS plane
        material.blending = THREE.NoBlending;
        material.map = this.resourcesRef.objectsLoaded.firewatchWallpaperTextureBlurred;

        // Create the GL plane mesh
        this.macbookScreenMeshClone = new THREE.Mesh(geometry, material);

        // // Copy the position, rotation and scale of the CSS plane to the GL plane
        // this.macbookScreenMesh.position.copy(this.css3Dobject.position);
        // this.macbookScreenMesh.rotation.copy(this.css3Dobject.rotation);
        // this.macbookScreenMesh.scale.copy(this.css3Dobject.scale);

        // Add to gl scene
        this.webGLsceneRef.add(this.macbookScreenMeshClone);
    }

    update()
    {
        if (this.workDenScene.macbookScreen)
        {
            // Copy World Rotation and World Position from Imported Macbook Screen object to CSS3D mesh plane
            // Get the global position of the GLTF object
            var globalPosition = new THREE.Vector3();
            this.workDenScene.macbookScreen.getWorldPosition(globalPosition);
            // Set the position of the plane to match the GLTF object's position
            this.css3Dobject.position.copy(globalPosition);
            // Get the global rotation quaternion of the GLTF object
            var globalQuaternion = new THREE.Quaternion();
            this.workDenScene.macbookScreen.getWorldQuaternion(globalQuaternion);
            // Also Rotate the plane by 90 degrees on the local Z-axis
            var localRotation = new THREE.Euler();
            localRotation.setFromQuaternion(globalQuaternion, 'XYZ'); // Convert global quaternion to local Euler rotation
            localRotation.z -= Math.PI / 2; // Rotate 90 degrees on local Z-axis
            // Apply the rotation of the GLTF object to the plane
            this.css3Dobject.setRotationFromEuler(localRotation);
            // Rotate the plane by 90 degrees on the global Y-axis
            this.css3Dobject.rotateY(Math.PI / 2);
            //this.css3Dobject.rotateX(Math.PI);

            this.macbookScreenMeshClone.position.copy(this.css3Dobject.position);
            this.macbookScreenMeshClone.rotation.copy(this.css3Dobject.rotation);
        }
    }
}