import * as THREE from 'three'
import coffeeSmokeVertexShader from '../../shaders/coffeeSmoke/vertex.glsl'
import coffeeSmokeFragmentShader from '../../shaders/coffeeSmoke/fragment.glsl'
import Experience from '../Experience';

export default class CoffeeSmoke {

    constructor(objectToAttach)
    {
        this.experienceRef = new Experience();
        this.webGLsceneRef = this.experienceRef.threeJSscene;
        this.timeRef = this.experienceRef.time;
        this.resourcesRef = this.experienceRef.resources;
        this.workdenSceneRef = this.experienceRef.sceneWorld.workDenScene;
        this.referenceObject = objectToAttach;
        this.createCoffeeSmoke();
    }

    createCoffeeSmoke(objectToAttach)
    {
        /**
         * Smoke
         */
        this.smokeGeometry = new THREE.PlaneGeometry(10, 10, 16, 64);
        this.smokeGeometry.translate(0, 0.5, 0);
        this.smokeGeometry.scale(1.5, 6, 1.5);

        // const smokeMaterial = new THREE.MeshBasicMaterial({
        //     color: 'cyan',
        //     wireframe: true
        // });

        // Perlin Texture
        this.perlinTexture = this.resourcesRef.objectsLoaded.perlinNoiseTexture;
        this.perlinTexture.wrapS = THREE.RepeatWrapping;
        this.perlinTexture.wrapT = THREE.RepeatWrapping;
        this.smokeMaterial = new THREE.ShaderMaterial({    
            vertexShader: coffeeSmokeVertexShader,
            fragmentShader: coffeeSmokeFragmentShader,
            uniforms:
            {
                //uPerlinTexture: { value: perlinTexture }
                uPerlinTexture: new THREE.Uniform(this.perlinTexture),
                uTime: new THREE.Uniform(0)
            },
            side: THREE.DoubleSide,
            transparent: true,
            // To prevent smoke plane from Occluding itself
            //depthTest: false
            //wireframe: true
        });

        this.smoke = new THREE.Mesh(this.smokeGeometry, this.smokeMaterial); 
        var globalPosition = new THREE.Vector3();
        this.referenceObject.getWorldPosition(globalPosition);
        globalPosition.y += 200;
        this.smoke.position.copy(globalPosition);        
        this.smoke.scale.set(20, 20, 10);
        this.webGLsceneRef.add(this.smoke);
    }

    update()
    {
        if (this.smokeMaterial)
        {
            // Update smoke material shader uniform
            this.smokeMaterial.uniforms.uTime.value = this.timeRef.elapsedTime;
        }
        
    }


}