import * as THREE from 'three'
import Experience from './Experience.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FlyControls } from 'three/examples/jsm/controls/FlyControls.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js'
//import { TransformControls } from 'three/addons/controls/TransformControls.js';
import gsap from 'gsap'

export default class Camera
{
    constructor(pExperience)
    {
        // Method 1
        // Getting reference to the Experience via the Globally exposed window
        // this.experience = window.experience;
        // console.log(this.experience.sizes.width);

        // Method 2 - via constructor parameter
        //this.experience  = pExperience;
        //console.log(this.experience.sizes.width);

        // Method 3 - Using a Singleton, get access to the Experience instance
        this.experienceRef = new Experience();
        this.sizesRef = this.experienceRef.sizes;
        this.sceneRef = this.experienceRef.threeJSscene;
        this.canvasRef = this.experienceRef.canvas;
        this.debugRef = this.experienceRef.debug;        
        this.cursor = new THREE.Vector2();
        this.resourcesRef = this.experienceRef.resources;

        // For custom camera controls / panning with damping
        this.cameraParent = new THREE.Group();
        this.cameraTargetPosX = 0;        
        this.cameraTargetZoom = 0;
        
        this.cameraState = 'default';
        this.isCameraTweening = false;

        this.setMainCameraInstance();
        //this.setCustomControls();
        //this.setFlyControls();
        this.setOrbitControls();


        //Wait for resources 'ready' event
        this.resourcesRef.on('ready', () =>
        {
            this.workDenSceneRef = this.experienceRef.sceneWorld.workDenScene;
            this.setCameraTransitions();
        });
        

        //this.enableCameraHelpers();
        if(this.debugRef.active)
        {            
            this.mainCameraDebugFolder = this.debugRef.ui.addFolder('Main Camera')           

            this.mainCameraDebugFolder.add(this, 'printMainCameraPostionAndRotation');
            this.mainCameraDebugFolder.add(this, 'enableCameraSplitScreenDebugMode');
            this.mainCameraDebugFolder.add(this, 'disableCameraSplitScreenDebugMode');

            this.mainCameraDebugSubFolder = this.mainCameraDebugFolder.addFolder('Position')
            this.mainCameraDebugSubFolder.add(this.cameraInstance.position, 'x').min(-5).max(5).step(0.00001)
            this.mainCameraDebugSubFolder.add(this.cameraInstance.position, 'y').min(-5).max(5).step(0.00001)
            this.mainCameraDebugSubFolder.add(this.cameraInstance.position, 'z').min(-5).max(5).step(0.00001)
            
            this.mainCameraDebugSubFolder = this.mainCameraDebugFolder.addFolder('Rotation')
            this.mainCameraDebugSubFolder.add(this.cameraInstance.rotation, 'x').min(-5).max(5).step(0.00001)
            this.mainCameraDebugSubFolder.add(this.cameraInstance.rotation, 'y').min(-5).max(5).step(0.00001)
            this.mainCameraDebugSubFolder.add(this.cameraInstance.rotation, 'z').min(-5).max(5).step(0.00001)
            
            this.mainCameraDebugSubFolder = this.mainCameraDebugFolder.addFolder('FOV')
            this.mainCameraDebugSubFolder.add(this.cameraInstance, 'fov')
                .min(5).max(50).step(0.00001)
                .onChange(()=> { this.cameraInstance.updateProjectionMatrix() });          


            // if (this.cameraHelper)
            // {
            //     this.debugFolder = this.debugRef.ui.addFolder('Helper Camera')
            //     this.cameraDebugFolder = this.debugFolder.addFolder('cameraPosition')
            //     this.cameraDebugFolder.add(this.helperCamera.position, 'x').min(-20).max(20).step(0.1)
            //     this.cameraDebugFolder.add(this.helperCamera.position, 'y').min(-20).max(20).step(0.1)
            //     this.cameraDebugFolder.add(this.helperCamera.position, 'z').min(-20).max(20).step(0.1)
            //     this.cameraDebugFolder = this.debugFolder.addFolder('cameraRotation')
            //     this.cameraDebugFolder.add(this.helperCamera.rotation, 'x').min(-20).max(20).step(0.0001)
            //     this.cameraDebugFolder.add(this.helperCamera.rotation, 'y').min(-20).max(20).step(0.0001)
            //     this.cameraDebugFolder.add(this.helperCamera.rotation, 'z').min(-20).max(20).step(0.0001)
            // }            
        }
    } 

    setMainCameraInstance()
    {
        /**
         * Approach 1
         */

        //this.cameraParent.position.set(0,0,0);
        //this.cameraParent.rotation.set(0,Math.PI / 4,0);
        //this.sceneRef.add(this.cameraParent);

        // this.cameraInstance = new THREE.PerspectiveCamera(37, (this.sizesRef.width / this.sizesRef.height), 0.1, 150)
        // this.cameraInstance.position.set(3.121, 1.608, 2.925);
        // this.cameraInstance.rotation.reorder('YXZ')
        // this.cameraInstance.rotation.set(-0.3, 0.8, 0);
        // this.cameraInstance.rotation.reorder('YXZ');
        // this.sceneRef.add(this.cameraInstance);

        //this.cameraParent.attach(this.cameraInstance);
        // OR
        //this.cameraParent.add(this.cameraInstance);

        /**
         * Approach 2
         */

        this.cameraInstance = new THREE.PerspectiveCamera(37, (this.sizesRef.width / this.sizesRef.height), 1000, 1000000)
        this.cameraInstance.position.set(-12000, 7000, 12000);        
        this.sceneRef.add(this.cameraInstance);
    }

    setCameraTransitions()
    {
        this.transitions = {}

        this.transitions.focusResumePile = async (duration) =>
        {   
            if (!this.isCameraTweening)
            {
                this.isCameraTweening = true;
                
                //this.customControlsEnabled = false;            
                //this.cameraParent.clear();
                //this.cameraInstance.matrixWorld.decompose( this.cameraInstance.position, this.cameraInstance.quaternion, this.cameraInstance.scale );
                //this.cameraParent.position.set(0,0,0);
                //this.cameraParent.rotation.set(0,Math.PI / 4,0);
                
                if (this.cameraState === 'default' || this.cameraState != 'resumeFocussed')
                {                
                    var globalResumePosition = new THREE.Vector3();
                    this.workDenSceneRef.resumeAbridged.getWorldPosition(globalResumePosition);

                    gsap.to(this.cameraInstance.position, {
                        duration : duration,
                        ease: "power1.inOut",
                        x: 0,
                        y: 7000,
                        z: 0
                    });

                    gsap.to(this.orbitControls.target, {
                        duration : duration,
                        ease: "power1.inOut",
                        x: globalResumePosition.x,
                        y: globalResumePosition.y,
                        z: globalResumePosition.z
                    });

                    
                    gsap.to(this.cameraInstance, {
                        duration : duration,
                        ease: "power1.inOut",
                        fov: 8,
                        onUpdate: () => {
                            this.cameraInstance.updateProjectionMatrix();
                        }
                    });
                    
                    await this.sleep(duration * 1000);
                    this.cameraState = 'resumeFocussed';
                    this.isCameraTweening = false;
                    // Restrict rotation on the y-axis                        
                    this.orbitControls.minAzimuthAngle = this.orbitControls.getAzimuthalAngle() - (Math.PI / 10); // or any fixed value
                    this.orbitControls.maxAzimuthAngle = this.orbitControls.getAzimuthalAngle() + (Math.PI / 5); // same fixed value
                }
            }            
        }

        this.transitions.focusLogoCluster = async (duration) =>
        {   
            if (!this.isCameraTweening)
            {
                this.isCameraTweening = true;

                // this.customControlsEnabled = false;            
                // this.cameraParent.clear();
                // this.cameraInstance.matrixWorld.decompose( this.cameraInstance.position, this.cameraInstance.quaternion, this.cameraInstance.scale );
                // this.cameraParent.position.set(0,0,0);
                // this.cameraParent.rotation.set(0,Math.PI / 4,0);
                
                if (this.cameraState === 'default' || this.cameraState != 'logoFocussed')
                {                
                    var globalLinkedinLogoPosition = new THREE.Vector3();
                    this.workDenSceneRef.linkedInLogo.getWorldPosition(globalLinkedinLogoPosition);
                    
                    gsap.to(this.cameraInstance.position, {
                        duration : duration,
                        ease: "power1.inOut",
                        x: 100,
                        y: 7000,
                        z: 0
                    });

                    gsap.to(this.orbitControls.target, {
                        duration : duration,
                        ease: "power1.inOut",
                        x: globalLinkedinLogoPosition.x,
                        y: globalLinkedinLogoPosition.y,
                        z: globalLinkedinLogoPosition.z
                    });

                    
                    gsap.to(this.cameraInstance, {
                        duration : duration,
                        ease: "power1.inOut",
                        fov: 25,
                        onUpdate: () => {
                            this.cameraInstance.updateProjectionMatrix();
                        }
                    });

                    
                    await this.sleep(duration * 1000);
                    this.cameraState = 'logoFocussed';
                    this.isCameraTweening = false;
                    // Restrict rotation on the y-axis                        
                    this.orbitControls.minAzimuthAngle = this.orbitControls.getAzimuthalAngle() - (Math.PI / 10); // or any fixed value
                    this.orbitControls.maxAzimuthAngle = this.orbitControls.getAzimuthalAngle() + (Math.PI / 5); // same fixed value
                }
            }           
        }

        this.transitions.focusDesktopMonitor = async (duration) =>
        {
            if (!this.isCameraTweening)
            {
                this.isCameraTweening = true;

                // this.customControlsEnabled = false;            
                // this.cameraParent.clear();
                // this.cameraInstance.matrixWorld.decompose( this.cameraInstance.position, this.cameraInstance.quaternion, this.cameraInstance.scale );
                // this.cameraParent.position.set(0,0,0);
                // this.cameraParent.rotation.set(0,Math.PI / 4,0);
                
                if (this.cameraState === 'default' || this.cameraState != 'monitorFocussed')
                {                
                    // disable raycasting on Desktop Monitor
                    this.workDenSceneRef.monitorScreen.raycast = function() {};

                    var globalMonitorPosition = new THREE.Vector3();
                    this.workDenSceneRef.monitorScreen.getWorldPosition(globalMonitorPosition);
                
                    this.cameraInstance.near = 5000;
                    this.cameraInstance.updateProjectionMatrix();

                    gsap.to(this.cameraInstance.position, {
                        duration : duration,
                        ease: "power1.inOut",
                        x: 0,
                        y: 3000,
                        z: 1000
                    });

                    gsap.to(this.orbitControls.target, {
                        duration : duration,
                        ease: "power1.inOut",
                        x: globalMonitorPosition.x,
                        y: globalMonitorPosition.y,
                        z: globalMonitorPosition.z
                    });

                    
                    gsap.to(this.cameraInstance, {
                        duration : duration,
                        ease: "power1.inOut",
                        fov: 15,
                        onUpdate: () => {
                            this.cameraInstance.updateProjectionMatrix();                        
                        }
                    });

                    
                    await this.sleep(duration * 1000);
                    this.cameraState = 'monitorFocussed';
                    this.isCameraTweening = false;
                    this.orbitControls.enableRotate = false;
                    
                    // Restrict rotation on the y-axis                        
                    //this.orbitControls.minAzimuthAngle = this.orbitControls.getAzimuthalAngle() - (Math.PI / 10); // or any fixed value
                    //this.orbitControls.maxAzimuthAngle = this.orbitControls.getAzimuthalAngle() + (Math.PI / 5); // same fixed value
                }
            }            
        }

        this.transitions.focusDraftingTableSketch = async (duration) =>
            {
                if (!this.isCameraTweening)
                {
                    this.isCameraTweening = true;
                    
                    if (this.cameraState === 'default' || this.cameraState != 'draftingTableFocussed')
                    {
                        // disable raycasting on Drafting Table Sketch
                        this.workDenSceneRef.draftingTableSketch.raycast = function() {};

                        var globalDraftingTablePosition = new THREE.Vector3();
                        this.workDenSceneRef.draftingTableSketch.getWorldPosition(globalDraftingTablePosition);
                    
                        this.cameraInstance.near = 5000;
                        this.cameraInstance.updateProjectionMatrix();
    
                        gsap.to(this.cameraInstance.position, {
                            duration : duration,
                            ease: "power1.inOut",
                            x: -1750,
                            y: 9000,
                            z: 0
                        });
    
                        gsap.to(this.orbitControls.target, {
                            duration : duration,
                            ease: "power1.inOut",
                            x: globalDraftingTablePosition.x,
                            y: globalDraftingTablePosition.y,
                            z: globalDraftingTablePosition.z
                        });
    
                        
                        gsap.to(this.cameraInstance, {
                            duration : duration,
                            ease: "power1.inOut",
                            fov: 15,
                            onUpdate: () => {
                                this.cameraInstance.updateProjectionMatrix();                        
                            }
                        });
    
                        
                        await this.sleep(duration * 1000);
                        this.cameraState = 'draftingTableFocussed';
                        this.isCameraTweening = false;
                        // Restrict rotation on the y-axis                        
                    this.orbitControls.minAzimuthAngle = this.orbitControls.getAzimuthalAngle() - (Math.PI / 10); // or any fixed value
                    this.orbitControls.maxAzimuthAngle = this.orbitControls.getAzimuthalAngle() + (Math.PI / 5); // same fixed value
                    }
                }            
        }

        this.transitions.focusDocumentFolder = async (duration) =>
            {
                if (!this.isCameraTweening)
                {
                    this.isCameraTweening = true;
                    
                    if (this.cameraState === 'default' || this.cameraState != 'documentFolderFocussed')
                    {
                        var globalDocumentFolderPosition = new THREE.Vector3();
                        this.workDenSceneRef.documentFolder.getWorldPosition(globalDocumentFolderPosition);
                    
                        this.cameraInstance.near = 5000;
                        this.cameraInstance.updateProjectionMatrix();
    
                        gsap.to(this.cameraInstance.position, {
                            duration : duration,
                            ease: "power1.inOut",
                            x: 0,
                            y: 6000,
                            z: 1000
                        });
    
                        gsap.to(this.orbitControls.target, {
                            duration : duration,
                            ease: "power1.inOut",
                            x: globalDocumentFolderPosition.x,
                            y: globalDocumentFolderPosition.y,
                            z: globalDocumentFolderPosition.z
                        });
    
                        
                        gsap.to(this.cameraInstance, {
                            duration : duration,
                            ease: "power1.inOut",
                            fov: 15,
                            onUpdate: () => {
                                this.cameraInstance.updateProjectionMatrix();                        
                            }
                        });
    
                        
                        await this.sleep(duration * 1000);
                        this.cameraState = 'documentFolderFocussed';
                        this.isCameraTweening = false;
                        // Restrict rotation on the y-axis                        
                        this.orbitControls.minAzimuthAngle = this.orbitControls.getAzimuthalAngle() - (Math.PI / 10); // or any fixed value
                        this.orbitControls.maxAzimuthAngle = this.orbitControls.getAzimuthalAngle() + (Math.PI / 5); // same fixed value

                    }
                }            
        }

        this.transitions.focusThreeJSlogo = async (duration) =>
            {
                if (!this.isCameraTweening)
                {
                    this.isCameraTweening = true;
                    
                    if (this.cameraState === 'default' || this.cameraState != 'threeJSLogoFocussed')
                    {                
                        var globalThreeJSlogoPosition = new THREE.Vector3();
                        this.workDenSceneRef.threeJsLogo.getWorldPosition(globalThreeJSlogoPosition);
                    
                        this.cameraInstance.near = 5000;
                        this.cameraInstance.updateProjectionMatrix();
    
                        gsap.to(this.cameraInstance.position, {
                            duration : duration,
                            ease: "power1.inOut",
                            x: 0,
                            y: 3000,
                            z: 1000
                        });
    
                        gsap.to(this.orbitControls.target, {
                            duration : duration,
                            ease: "power1.inOut",
                            x: globalThreeJSlogoPosition.x,
                            y: globalThreeJSlogoPosition.y,
                            z: globalThreeJSlogoPosition.z
                        });
    
                        
                        gsap.to(this.cameraInstance, {
                            duration : duration,
                            ease: "power1.inOut",
                            fov: 15,
                            onUpdate: () => {
                                this.cameraInstance.updateProjectionMatrix();                        
                            }
                        });
    
                        
                        await this.sleep(duration * 1000);
                        this.cameraState = 'threeJSLogoFocussed';
                        this.isCameraTweening = false;
                        // Restrict rotation on the y-axis                        
                    this.orbitControls.minAzimuthAngle = this.orbitControls.getAzimuthalAngle() - (Math.PI / 10); // or any fixed value
                    this.orbitControls.maxAzimuthAngle = this.orbitControls.getAzimuthalAngle() + (Math.PI / 5); // same fixed value
                    }
                }            
        }

        this.transitions.focusDesktopPcCabinet = async (duration) =>
        {
            if (!this.isCameraTweening)
            {
                this.isCameraTweening = true;
                
                if (this.cameraState === 'default' || this.cameraState != 'desktopPcCabinetFocussed')
                {
                    // disable raycasting on Desktop PC Cabinet
                    this.workDenSceneRef.desktopPcCabinet.raycast = function() {};

                    var globalDesktopPcCabinetPosition = new THREE.Vector3();
                    this.workDenSceneRef.desktopPcCabinet.getWorldPosition(globalDesktopPcCabinetPosition);
                
                    this.cameraInstance.near = 5000;
                    this.cameraInstance.updateProjectionMatrix();

                    gsap.to(this.cameraInstance.position, {
                        duration : duration,
                        ease: "power1.inOut",
                        x: -1000,
                        y: 7000,
                        z: 2000
                    });

                    gsap.to(this.orbitControls.target, {
                        duration : duration,
                        ease: "power1.inOut",
                        x: globalDesktopPcCabinetPosition.x,
                        y: globalDesktopPcCabinetPosition.y,
                        z: globalDesktopPcCabinetPosition.z
                    });

                    
                    gsap.to(this.cameraInstance, {
                        duration : duration,
                        ease: "power1.inOut",
                        fov: 17,
                        onUpdate: () => {
                            this.cameraInstance.updateProjectionMatrix();                        
                        }
                    });

                    
                    await this.sleep(duration * 1000);
                    this.cameraState = 'desktopPcCabinetFocussed';
                    this.isCameraTweening = false;
                    // Restrict rotation on the y-axis                        
                    this.orbitControls.minAzimuthAngle = this.orbitControls.getAzimuthalAngle() - (Math.PI / 10); // or any fixed value
                    this.orbitControls.maxAzimuthAngle = this.orbitControls.getAzimuthalAngle() + (Math.PI / 5); // same fixed value
                }
            }            
        }

        this.transitions.focusMacbookScreen = async (duration) =>
            {
                if (!this.isCameraTweening)
                {
                    this.isCameraTweening = true;
                    
                    if (this.cameraState === 'default' || this.cameraState != 'macbookScreenFocussed')
                    {              
                        // disable raycasting on Macbook screen
                        this.workDenSceneRef.macbookScreen.raycast = function() {};

                        // Diable horizontal Pan rotation limits for Camera transition to avoid camera jumping
                        this.orbitControls.minAzimuthAngle = -Infinity;
                        this.orbitControls.maxAzimuthAngle = Infinity;
                        this.orbitControls.enabled = false;
                        
                        var globalMacbookScreenPosition = new THREE.Vector3();
                        this.workDenSceneRef.macbookScreen.getWorldPosition(globalMacbookScreenPosition);
                    
                        this.cameraInstance.near = 1000;
                        this.cameraInstance.updateProjectionMatrix();                        

                        gsap.to(this.cameraInstance.position, {
                            duration : duration,
                            ease: "power1.inOut",
                            x: 0,
                            y: 4000,
                            z: 0
                        });

                        gsap.to(this.orbitControls.target, {
                            duration : duration,
                            ease: "power1.inOut",
                            x: globalMacbookScreenPosition.x,
                            y: globalMacbookScreenPosition.y,
                            z: globalMacbookScreenPosition.z
                        });
                        
                        gsap.to(this.cameraInstance, {
                            duration : duration,
                            ease: "power1.inOut",
                            fov: 18,
                            onUpdate: () => {
                                this.cameraInstance.updateProjectionMatrix();                        
                            }
                        });

                        
                        await this.sleep(duration * 1000);
                        this.cameraState = 'macbookScreenFocussed';
                        this.isCameraTweening = false;

                        // Restrict rotation on the y-axis                        
                        //this.orbitControls.minAzimuthAngle = this.orbitControls.getAzimuthalAngle() - (Math.PI / 5); // or any fixed value
                        //this.orbitControls.maxAzimuthAngle = this.orbitControls.getAzimuthalAngle() + (Math.PI / 5); // same fixed value
                        //this.orbitControls.enabled = true;                        
                    }
                }            
            }

        this.transitions.resetToDefaultPos = async (duration) =>
        {
            if (!this.isCameraTweening)
            {
                this.isCameraTweening = true;

                if (this.cameraState != 'default')
                {
                    // Diable horizontal Pan rotation limits for Camera transition to avoid camera jumping
                    this.orbitControls.minAzimuthAngle = -Infinity;
                    this.orbitControls.maxAzimuthAngle = Infinity;
                    this.orbitControls.enabled = false;

                    gsap.to(this.cameraInstance.position, {
                        duration : duration,
                        ease: "power1.inOut",
                        x: -12000,
                        y: 7000,
                        z: 12000
                    });
            
                    gsap.to(this.orbitControls.target, {
                        duration : duration,
                        ease: "power1.inOut",
                        x: 0,
                        y: 2000,
                        z: 0
                    });
            
                    
                    gsap.to(this.cameraInstance, {
                        duration : duration,
                        ease: "power1.inOut",
                        fov: 37,
                        onUpdate: () => {
                            this.cameraInstance.updateProjectionMatrix();                        
                        }
                    });                    

                    await this.sleep(duration * 1000);
                    this.cameraState = 'default';
                    this.isCameraTweening = false;                    
                    this.orbitControls.enableRotate = true;
                    // Enable webgl pointer events so CSS iFrame element becomes Non-Interactive
                    document.querySelector('#webgl-three-js').style.pointerEvents = 'auto';

                    this.cameraInstance.near = 1000;
                    this.cameraInstance.updateProjectionMatrix();                    

                    this.orbitControls.minAzimuthAngle = -Math.PI/2;
                    this.orbitControls.maxAzimuthAngle = Math.PI/10;
                    this.orbitControls.enabled = true;

                    // Reset to default raycasting behavior
                    delete this.workDenSceneRef.macbookScreen.raycast;
                    delete this.workDenSceneRef.monitorScreen.raycast;
                    delete this.workDenSceneRef.desktopPcCabinet.raycast;
                    delete this.workDenSceneRef.draftingTableSketch.raycast;                    
                }
            }            
        }
    }

    printMainCameraPostionAndRotation()
    {
        console.log(this.cameraInstance.position);
        console.log(this.cameraInstance.rotation);
    }

    enableCameraSplitScreenDebugMode()
    {        
        this.enableCameraHelpersForDebugMode();
        this.setMainCameraTransformControlsForDebug();

        this.helperCameraDebugFolder = this.debugRef.ui.addFolder('Helper Camera')

        this.helperCameraDebugSubFolder = this.helperCameraDebugFolder.addFolder('position')
        this.helperCameraDebugSubFolder.add(this.helperCamera.position, 'x').min(-10).max(10).step(0.001)
        this.helperCameraDebugSubFolder.add(this.helperCamera.position, 'y').min(-10).max(10).step(0.001)
        this.helperCameraDebugSubFolder.add(this.helperCamera.position, 'z').min(-10).max(10).step(0.001)

        this.helperCameraDebugSubFolder = this.helperCameraDebugFolder.addFolder('rotation')
        this.helperCameraDebugSubFolder.add(this.helperCamera.rotation, 'x').min(-10).max(10).step(0.00001)
        this.helperCameraDebugSubFolder.add(this.helperCamera.rotation, 'y').min(-10).max(10).step(0.00001)
        this.helperCameraDebugSubFolder.add(this.helperCamera.rotation, 'z').min(-10).max(10).step(0.00001)

        this.experienceRef.isCameraSplitScreenDebugModeEnabled = true;
        this.customControlsEnabled = false;
    } 

    enableCameraHelpersForDebugMode()
    {
        // Camera helpers
        this.cameraHelper = new THREE.CameraHelper(this.cameraInstance);
        this.sceneRef.add(this.cameraHelper);
        
        // scene axes helper
        this.sceneRef.add(new THREE.AxesHelper(20))

        // helper camera
        this.helperCamera = new THREE.PerspectiveCamera(50, (this.sizesRef.width / this.sizesRef.height), 0.1, 150)
        this.helperCamera.position.set(2, 2.7, 9);
        this.cameraInstance.rotation.reorder('YXZ')
        this.helperCamera.rotation.set(0,0,0);
        this.cameraInstance.rotation.reorder('YXZ')
        //this.cameraHelper.lookAt(new THREE.Vector3(0,0,0))
        this.sceneRef.add(this.helperCamera);
    }    

    setMainCameraTransformControlsForDebug()
    {
         // Add Transform controls to main camera
         this.mainCameraTransformControls = new TransformControls(this.helperCamera, this.canvasRef);
         this.mainCameraTransformControls.attach(this.cameraInstance);
         this.mainCameraTransformControls.setSpace("local");
         this.mainCameraTransformControlsObjectSpace = "local"
         this.mainCameraTransformControls.setMode("translate");
         this.sceneRef.add(this.mainCameraTransformControls);

         window.addEventListener( 'resize', this.experienceRef.onWindowResize );
         window.addEventListener( 'keydown', (event) =>
         {
            switch ( event.code ) 
            {
                case "KeyQ": // Q
                    this.mainCameraTransformControls.setSpace(this.mainCameraTransformControls.space === 'local' ? 'world' : 'local' );
                    break;

                case "ShiftLeft": // Shift
                    this.mainCameraTransformControls.setTranslationSnap( 1 );
                    this.mainCameraTransformControls.setRotationSnap( THREE.MathUtils.degToRad( 15 ) );
                    this.mainCameraTransformControls.setScaleSnap( 0.25 );
                    break;

                case "KeyW": // W                
                    this.mainCameraTransformControls.setMode("translate");
                    break;

                case "KeyE": // E                    
                    this.mainCameraTransformControls.setMode("rotate");
                    break;

                case "KeyR": // R
                    this.mainCameraTransformControls.setMode("scale");
                    break;
            }
         });
    }

    disableCameraSplitScreenDebugMode()
    {
        this.cameraHelper.removeFromParent();
        this.sceneRef.remove(this.cameraHelper);
        this.mainCameraTransformControls.removeFromParent();
        this.sceneRef.remove(this.mainCameraTransformControls);
        
        this.helperCameraDebugSubFolder.destroy();
        this.helperCameraDebugFolder.destroy();        
        
        this.experienceRef.renderer.rendererInstance.setClearColor('#000000')
        this.experienceRef.renderer.rendererInstance.setViewport(0, 0, this.sizesRef.width, this.sizesRef.height);
        this.cameraInstance.aspect = this.sizesRef.width / this.sizesRef.height;
        this.cameraInstance.updateProjectionMatrix();
        //this.experienceRef.renderer.rendererInstance.setScissor(0, 0, this.sizesRef.width, this.sizesRef.height);
        this.experienceRef.renderer.rendererInstance.setScissorTest(false);
        this.experienceRef.isCameraSplitScreenDebugModeEnabled = false;
        this.customControlsEnabled = true;
    }

    setFlyControls()
    {
        this.flyControls = new FlyControls(this.cameraInstance, this.canvasRef);
        this.flyControls.movementSpeed = 100;
        this.flyControls.rollSpeed = Math.PI/24;
        this.flyControls.autoForward = false;
        this.flyControls.dragToLook = true;
    }

    setOrbitControls()
    {
        this.orbitControls = new OrbitControls(this.cameraInstance, this.canvasRef);
        this.orbitControls.enableDamping = true;
        this.orbitControls.target.set(0,2000, 0);
        this.orbitControls.enablePan = false;
        //this.orbitControls.enableRotate = false;
        //this.orbitControls.enableZoom = false;        
        this.orbitControls.maxPolarAngle = Math.PI/2;
        this.orbitControls.minPolarAngle = Math.PI/4;
        this.orbitControls.maxAzimuthAngle = Math.PI/10;
        this.orbitControls.minAzimuthAngle = -Math.PI/2;
        // Set the zooming bounds
        this.orbitControls.minDistance = 2000;  // Minimum distance to the target (closest zoom)
        this.orbitControls.maxDistance = 22000; // Maximum distance from the target (farthest zoom)

        this.orbitControls.addEventListener('change', function()
        {
            //...
            //this.target.y = 0;
            //this.cameraInstance.position.y = 0;
            //...

            //console.log(this.orbitControls)
        })
    }

    setCustomControls()
    {
        this.customControlsEnabled = true;
        //Cursor
        window.addEventListener('mousemove', (event) => 
        {
            this.cursor.x = event.clientX / this.sizesRef.width - 0.5;
            this.cursor.y = event.clientY / this.sizesRef.height - 0.5;
        });
    }

    onWindowResize()
    {
        this.cameraInstance.aspect = this.sizesRef.width / this.sizesRef.height;
        this.cameraInstance.updateProjectionMatrix();
        if(this.helperCamera)
        {
            //this.helperCamera.aspect = this.sizesRef.width / this.sizesRef.height;
            this.helperCamera.updateProjectionMatrix();
        }            
        //console.log('Camera resize updated');
    }

    updateControls(deltaTime)
    {
        if (this.orbitControls)
            this.orbitControls.update();

        if (this.flyControls)
            this.flyControls.update(0.001);

        if(this.customControlsEnabled)
        {
            // Update camera postion
            // When Camera Parent was used
            this.cameraTargetPosX = THREE.MathUtils.lerp(this.cameraInstance.position.x, this.cameraParent.position.x - this.cursor.x, deltaTime * 0.00003);            
            if(this.cameraTargetPosX > 0.05 && this.cameraTargetPosX < 0.23)
            {
                this.cameraInstance.position.x = this.cameraTargetPosX;                
            }            
           
            // Update camera zoom
            this.cameraTargetZoom = THREE.MathUtils.lerp(this.cameraInstance.fov, this.cameraInstance.fov + this.cursor.y, deltaTime * 0.0005);
            if(this.cameraTargetZoom >= 35 && this.cameraTargetZoom <= 40)
            {            
                this.cameraInstance.fov = this.cameraTargetZoom;
                this.cameraInstance.updateProjectionMatrix();
            }
        }

        if (this.orbitControls)
        {
            // Print the current camera zoom value (distance from target)
            //console.log(this.cameraInstance.position.length());
            //console.log(this.orbitControls);
        }

    }

    sleep(ms) 
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}