//https://jsfiddle.net/prisoner849/jm0vb71c/, https://discourse.threejs.org/t/selective-bloom-parts-of-a-single-geometry/28683
// https://stackoverflow.com/questions/67014085/threejs-selective-bloom-for-specific-parts-of-one-object-using-emission-map


//https://github.com/vanruesc/postprocessing


import * as THREE from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'  
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js'
import vertexShader from '../shaders/bloomShaders/vertex.glsl'
import fragmentShader from '../shaders/bloomShaders/fragment.glsl'
import Experience from './Experience.js'

export default class PostProcessing
{
    constructor()
    {
        this.experienceRef = new Experience();
        this.canvasRef = this.experienceRef.canvas;
        this.rendererRef = this.experienceRef.renderer;
        this.sceneRef = this.experienceRef.threeJSscene;        
        this.cameraRef = this.experienceRef.camera;
        this.sizesRef = this.experienceRef.sizes;
        this.resourcesRef = this.experienceRef.resources;

        //this.update = function update() {}        

        // Wait for resources
        this.resourcesRef.on('ready', () =>
        {
            this.workDenScene = this.experienceRef.threeJSscene.workDenScene;
            this.applyPostProcessing();            
        })
    }

    applyPostProcessing()
    {
        /**
        * Post Processing
        */
        // Create a render target for Anti Alais
        // Size doesn't matter since SetSize() will anyway set them        
        const ppRenderTarget = new THREE.WebGLRenderTarget(
            800,
            600,
            {
                // Only works on browsers supporting WebGL 2.0
                // To re-activate Anti-Alias if user has pixel ratio equal to 1
                samples : this.rendererRef.webGLrendererInstance.getPixelRatio() >= 1 ? 4 : 0,
                //samples: 8
                // Samples property above will be ignored if the browser is doesn't support WebGL2 without triggering any errors
                // Hence no need to add WebGL 1.0 checks here
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                colorSpace: THREE.SRGBColorSpace
            }
        );

        //console.log(this.rendererRef.rendererInstance.capabilities);
        //console.log(ppRenderTarget.samples);
        this.effectComposer = new EffectComposer(this.rendererRef.webGLrendererInstance, ppRenderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizesRef.width, this.sizesRef.height);

        // First Render Pass
        this.firstWebGLRenderPass = new RenderPass(this.sceneRef, this.cameraRef.cameraInstance);
        this.effectComposer.addPass(this.firstWebGLRenderPass);        

        // Outline pass for hovering and selecting objects
        this.outlinePass = new OutlinePass(
            new THREE.Vector2(window.innerWidth, window.innerHeight),
            this.sceneRef, 
            this.cameraRef.cameraInstance
            );
        this.outlinePass.edgeStrength  = 10;
        this.outlinePass.edgeGlow = 0;
        this.outlinePass.edgeThickness = 4;
        this.outlinePass.visibleEdgeColor.set('#FFFFFF');
        this.outlinePass.hiddenEdgeColor.set('#FFFFFF');
        this.outlinePass.enabled = false;
		this.effectComposer.addPass(this.outlinePass);

        // Unreal Bloom Pass
        this.unrealBloomPass = new UnrealBloomPass();
        this.unrealBloomPass.strength = 0.2;
        this.unrealBloomPass.radius = 0.8;
        this.unrealBloomPass.threshold = 0.1;
        this.effectComposer.addPass(this.unrealBloomPass);

        // Gamma Correction Pass
        this.gammaCorrectionPass = new ShaderPass(GammaCorrectionShader);
        this.effectComposer.addPass(this.gammaCorrectionPass);

        // Anti-Aliasing Pass
        this.antiAliasPass = new SMAAPass();
        this.effectComposer.addPass(this.antiAliasPass);

        // Output Pass that does the sRGB color space conversion and tone mapping
        //this.outputPass = new OutputPass();
        //this.effectComposer.addPass(this.outputPass);
    }

    updatePP()
    {
        this.effectComposer.render();
    }
    
    resize()
    {
        // if(this.effectComposer) {this.effectComposer.setSize(this.sizesRef.width, this.sizesRef.height)}
        // if(this.effectComposer) {this.effectComposer.setPixelRatio(Math.min(this.sizesRef.pixelRatio, 2))}
        // if(this.finalComposer) {this.finalComposer.setSize(this.sizesRef.width, this.sizesRef.height)}
        // if(this.finalComposer) {this.finalComposer.setPixelRatio(Math.min(this.sizesRef.pixelRatio, 2))}
        
        if(this.effectComposer) {this.effectComposer.setSize(this.sizesRef.width, this.sizesRef.height)}
        if(this.effectComposer) {this.effectComposer.setPixelRatio(Math.min(this.sizesRef.pixelRatio, 2))}
    }
}

