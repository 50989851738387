import * as THREE from 'three';
import Experience from "../Experience.js";

export default class Environment
{
    constructor()
    {
        // get ref to Experience singleton
        this.experienceRef = new Experience();
        this.sceneRef = this.experienceRef.threeJSscene;
        this.resourcesRef = this.experienceRef.resources;
        this.debugRef = this.experienceRef.debug;

        // Debug
        if (this.debugRef.active)
        {
            this.debugFolder = this.debugRef.ui.addFolder('Environment');
        }

        // Setup
        this.setSunlight();
        //this.setAmbientLight();
        //this.setPointLights();
        //this.setEnvironmentMap();        
    }

    setSunlight()
    {
        this.directionalLightColor = new THREE.Color('#EEE544'); // Color in sRGB space
        this.directionalLightColor.convertSRGBToLinear(); // Convert sRGB to linear space
        this.sunLight = new THREE.DirectionalLight(this.directionalLightColor, 2);
        //this.sunLight = new THREE.DirectionalLight('#6000D7', 0.5)
        this.sunLight.castShadow = true
        this.sunLight.shadow.camera.far = 15
        this.sunLight.shadow.mapSize.set(1024, 1024)
        this.sunLight.shadow.normalBias = 0.05
        this.sunLight.position.set(-3, 5, 5);        
        this.sunLight.color.set(0.933, 0.898, 0.266);
        this.sceneRef.add(this.sunLight)
        

        //gui.add(directionalLight, 'intensity').min(0).max(10).step(0.001).name('lightIntensity')
        //gui.add(directionalLight.position, 'x').min(- 5).max(5).step(0.001).name('lightX')
        //gui.add(directionalLight.position, 'y').min(- 5).max(5).step(0.001).name('lightY')
        //gui.add(directionalLight.position, 'z').min(- 5).max(5).step(0.001).name('lightZ')
        
        // For Debug UI
        if (this.debugRef.active)
        {
            this.debugFolder
                .addColor(this.sunLight, 'color')

            this.debugFolder
                .add(this.sunLight,'intensity')
                .name('lightIntensity')
                .min(0)
                .max(10)
                .step(0.01);

             this.debugFolder
                .add(this.sunLight.position,'x')
                .name('sunLightX')
                .min(-5)
                .max(5)
                .step(0.01);    

            this.debugFolder
                .add(this.sunLight.position,'y')
                .name('sunLightY')
                .min(-5)
                .max(5)
                .step(0.01); 
                
            this.debugFolder
                .add(this.sunLight.position,'z')
                .name('sunLightZ')
                .min(-5)
                .max(5)
                .step(0.01); 
        }
    }

    setAmbientLight()
    {
        this.ambientLight = new THREE.AmbientLight();
    }

    setPointLights()
    {
        this.treeLampLight1 = new THREE.PointLight(0xFFDB00, 500, 200);
        this.treeLampLight1.position.set(5000 , 4900, -4100);
        this.sceneRef.add(this.treeLampLight1);

        this.treeLampLight1Helper = new THREE.PointLightHelper(this.treeLampLight1, 100);
        this.sceneRef.add(this.treeLampLight1Helper);
    }

    setEnvironmentMap()
    {
        this.environmentMap = {};
        this.environmentMap.intensity = 0.4;
        this.environmentMap.texture = this.resourcesRef.objectsLoaded.environmentMapTexture;
        this.environmentMap.texture.colorSpace = THREE.SRGBColorSpace;

        this.sceneRef.environment = this.environmentMap.texture;

        // Update all Materials to use this environment map
        this.environmentMap.updateMaterials = () =>
        {
            this.sceneRef.traverse((child) =>
            {
                if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
                {
                    child.material.envMap = this.environmentMap.texture;
                    child.material.envMapIntensity = this.environmentMap.intensity;
                    child.material.needsUpdate = true;                    
                }
            })
        }

        this.environmentMap.updateMaterials();
        //console.log("Instantiating Env map COMPLETE");

        // For Debug UI
        if (this.debugRef.active)
        {
            this.debugFolder
                .add(this.environmentMap, 'intensity')
                .name('envMapIntensity')
                .min(0)
                .max(4)
                .step(0.001)
                .onChange(this.environmentMap.updateMaterials);
        }
    }
}


