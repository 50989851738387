import Experience from "./Experience.js";
import * as THREE from 'three'
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer.js';

export default class Renderer {
    constructor()
    {
        // get ref to Experience singleton
        this.experienceRef = new Experience();
        this.canvasRef = this.experienceRef.canvas;
        this.sizesRef = this.experienceRef.sizes;
        this.sceneRef = this.experienceRef.threeJSscene;
        this.cameraRef = this.experienceRef.camera;        
        this.cssSceneRef = this.experienceRef.cssScene;        

        this.setWebGLRendererInstance();
        this.setCSS3DRendererInstance();
    }

    setCSS3DRendererInstance()
    {
        this.css3DrendererInstance = new CSS3DRenderer();        
        this.css3DrendererInstance.setSize(this.sizesRef.width, this.sizesRef.height);
        this.css3DrendererInstance.domElement.style.position = 'absolute';
        this.css3DrendererInstance.domElement.style.top = '0px';

        document.querySelector('#css-renderer')?.appendChild(this.css3DrendererInstance.domElement);
    }

    setWebGLRendererInstance()
    {
        this.webGLrendererInstance = new THREE.WebGLRenderer(
            {
                //canvas : this.canvasRef,
                // If using Post Processing, the Effect Composer doesn't support the default Anti-Alias
                antialias : true,
                powerPreference : 'high-performance'
            });
            
        this.webGLrendererInstance.useLegacyLights = false        
        this.webGLrendererInstance.toneMapping = THREE.CineonToneMapping;
        this.webGLrendererInstance.outputColorSpace = THREE.SRGBColorSpace;
        this.webGLrendererInstance.toneMappingExposure = 1.75
        this.webGLrendererInstance.shadowMap.enabled = true
        this.webGLrendererInstance.shadowMap.type = THREE.PCFSoftShadowMap
        this.webGLrendererInstance.setClearColor('#000000')
        this.webGLrendererInstance.setSize(this.sizesRef.width, this.sizesRef.height)
        this.webGLrendererInstance.setPixelRatio(Math.min(window.devicePixelRatio, 2))       
        
        document.querySelector('#webgl-three-js')?.appendChild(this.webGLrendererInstance.domElement);        

        //console.log("renderers created")
    }

    onWindowResize()
    {
        if (this.webGLrendererInstance)
        {
            this.webGLrendererInstance.setSize(this.sizesRef.width, this.sizesRef.height);
            this.webGLrendererInstance.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        }        
        
        if(this.css3DrendererInstance)
            this.css3DrendererInstance.setSize(this.sizesRef.width, this.sizesRef.height);
        //console.log("Renderer resized");
    }

    updateRenderers(shouldUpdateWebGLRenderer, shouldUpdateCSS3DRenderer)
    {       
        if (shouldUpdateWebGLRenderer)
            this.updateWebGLrenderer();
        
        if (shouldUpdateCSS3DRenderer)
            this.updateCSS3Drenderer();
    }

    updateWebGLrenderer()
    {
        if (this.webGLrendererInstance)
        {
            if(this.experienceRef.isCameraSplitScreenDebugModeEnabled)
            {            
                // Render the scene with camera1
                this.webGLrendererInstance.setClearColor('#000000')
                this.webGLrendererInstance.setViewport(0, 0, this.sizesRef.width / 2, this.sizesRef.height);
                this.cameraRef.cameraInstance.aspect = (this.sizesRef.width / 2) / this.sizesRef.height;
                this.cameraRef.cameraInstance.updateProjectionMatrix();
                this.webGLrendererInstance.setScissor(0, 0, this.sizesRef.width / 2, this.sizesRef.height);
                this.webGLrendererInstance.setScissorTest(true);
                this.webGLrendererInstance.render(this.sceneRef, this.cameraRef.cameraInstance);
                
                // Render the scene with camera2            
                this.webGLrendererInstance.setClearColor('#8B92E7')
                this.webGLrendererInstance.setViewport(this.sizesRef.width / 2, 0, this.sizesRef.width / 2, this.sizesRef.height);
                this.cameraRef.helperCamera.aspect = (this.sizesRef.width / 2) / this.sizesRef.height;
                this.cameraRef.helperCamera.updateProjectionMatrix();
                this.webGLrendererInstance.setScissor(this.sizesRef.width / 2, 0, this.sizesRef.width / 2, this.sizesRef.height);
                this.webGLrendererInstance.setScissorTest(true);
                this.webGLrendererInstance.render(this.sceneRef, this.cameraRef.helperCamera);
            }
            else
            {
                this.webGLrendererInstance.render(this.sceneRef, this.cameraRef.cameraInstance);            
            }
        }        
    }

    updateCSS3Drenderer()
    {
        if (this.css3DrendererInstance)
            this.css3DrendererInstance.render(this.cssSceneRef, this.cameraRef.cameraInstance);
    }
}